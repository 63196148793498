import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import navigationBarPhoto from "../../../../assets/images/navBar.png";
import Container from "../../../../bootstrap/Container/";
import SinglePhoto from "../../SinglePhoto/";
import TrackOrder from "../../TrackOrder";
import {
  HeaderBottomWrapper,
  Logo,
  LogoLink,
  NavigationBar,
  NavigationBarIcon,
  NavigationUl,
  NavigationWithOutRes,
  NavigationWrapper,
  NavLink,
  SingleNavItem,
  Wrapper,
} from "./HeaderBottom.styles.js";

import {
  webSetting,
} from "../../../../redux/action/busAction";
import languageData from "../../../../lib/lang.config.json";

const HeaderBottom = ({ userProfileInfo }) => {
  const { webSettingData } = useSelector(
    (state) => state.busLists
  );
  const [showNavItem, setShowNavItem] = useState(false);
  const [token, setToken] = useState(null);
  const dispatch = useDispatch();
  const languages = {
    "en" : "English",
    "sw" : "Swahili"
  }

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  const changeLanguage = (lang) => {
    webSettingData['language'] = lang;
    dispatch(webSetting(webSettingData));
  }

  return (
    <Wrapper bg={webSettingData?.headercolor}>
      <Container>
        <HeaderBottomWrapper>
          <Logo>
            <LogoLink to="/">
              <SinglePhoto
                img={webSettingData?.headerlogo}
                alt="logo"
                style={{ height: "50px" }}
              />
            </LogoLink>
            <NavigationBar>
              <NavigationBarIcon
                src={navigationBarPhoto}
                alt="navigationIcon"
                onClick={() => setShowNavItem((prevState) => !prevState)}
              />
            </NavigationBar>
          </Logo>
          <NavigationWrapper>
            <NavigationWithOutRes>
              <SingleNavItem>
                <NavLink to="/">
                  {
                    languageData?.navigation_home_button[
                      webSettingData?.language
                    ]
                  }
                </NavLink>
              </SingleNavItem>
              <SingleNavItem>
                <NavLink to="/work">
                  {
                    languageData?.navigation_work_button[
                      webSettingData?.language
                    ]
                  }
                </NavLink>
              </SingleNavItem>
              <SingleNavItem>
                <NavLink to="/blog">
                  {
                    languageData?.navigation_blog_button[
                      webSettingData?.language
                    ]
                  }
                </NavLink>
              </SingleNavItem>

              <SingleNavItem>
                <Popup
                  trigger={
                    <NavLink to="#">
                      {
                        languageData?.navigation_track_button[
                          webSettingData?.language
                        ]
                      }
                    </NavLink>
                  }
                  position="bottom center"
                >
                  <TrackOrder />
                </Popup>
              </SingleNavItem>

              <SingleNavItem>
              <div class="sl-nav">
                <ul>
                  <li>{languages[webSettingData?.language]}<i class="fa fa-angle-down" aria-hidden="true"></i>
                    <div class="triangle"></div>
                    <ul>
                      {
                        Object.entries(languages).map(([key,value],i)=>(
                          <li onClick={()=>changeLanguage(key)}><span>{value}</span></li>
                        ))
                      }
                    </ul>
                  </li>
                </ul>
              </div>
              </SingleNavItem>

              {token ? (
                <SingleNavItem>
                  <NavLink to="/tickets">{userProfileInfo?.first_name}</NavLink>
                </SingleNavItem>
              ) : (
                <SingleNavItem>
                  <NavLink to="/login">
                    {
                      languageData?.navigation_login_button[
                        webSettingData?.language
                      ]
                    }
                  </NavLink>
                </SingleNavItem>
              )}
            </NavigationWithOutRes>

            {showNavItem && (
              <NavigationUl>
                <SingleNavItem>
                  <NavLink to="/">
                    {" "}
                    {
                      languageData?.navigation_home_button[
                        webSettingData?.language
                      ]
                    }
                  </NavLink>
                </SingleNavItem>
                <SingleNavItem>
                  <NavLink to="/work">
                    {
                      languageData?.navigation_work_button[
                        webSettingData?.language
                      ]
                    }
                  </NavLink>
                </SingleNavItem>
                <SingleNavItem>
                  <NavLink to="/blog">
                    {" "}
                    {
                      languageData?.navigation_blog_button[
                        webSettingData?.language
                      ]
                    }
                  </NavLink>
                </SingleNavItem>

                <SingleNavItem>
                  <Popup
                    trigger={
                      <NavLink to="#">
                        {" "}
                        {
                          languageData?.navigation_track_button[
                            webSettingData?.language
                          ]
                        }{" "}
                      </NavLink>
                    }
                    position="bottom center"
                  >
                    <TrackOrder />
                  </Popup>
                </SingleNavItem>

                <SingleNavItem>
              <div class="sl-nav">
                <ul>
                  <li>{languages[webSettingData?.language]}<i class="fa fa-angle-down" aria-hidden="true"></i>
                    <div class="triangle"></div>
                    <ul>
                      {
                        Object.entries(languages).map(([key,value],i)=>(
                          <li onClick={()=>changeLanguage(key)}><span>{value}</span></li>
                        ))
                      }
                    </ul>
                  </li>
                </ul>
              </div>
              </SingleNavItem>

                {token ? (
                  <SingleNavItem>
                    <NavLink to="/tickets">
                      {userProfileInfo?.first_name}
                    </NavLink>
                  </SingleNavItem>
                ) : (
                  <SingleNavItem>
                    <NavLink to="/login">
                      {
                        languageData?.navigation_login_button[
                          webSettingData?.language
                        ]
                      }
                    </NavLink>
                  </SingleNavItem>
                )}
              </NavigationUl>
            )}
          </NavigationWrapper>
        </HeaderBottomWrapper>
      </Container>
    </Wrapper>
  );
};

export default HeaderBottom;
